<template>
  <a-form :form="form">
    <a-form-item label="请选择要添加的子标签" style="width: 80%">
      <a-select
        show-search
        v-decorator="['childLabelIds']"
        placeholder="选择"
        mode="multiple"
        option-filter-prop="children"
        :filter-option="filterOption"
      >
        <a-select-option
          v-for="item in labelData"
          :value="item.id"
          :key="item.id"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
    </a-form-item>
  </a-form>
</template>

<script>
import { addChildLabel } from "@/api/tag";

export default {
  props: {
    labelId: {
      type: Number,
      require: true,
    },
    labelData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
    };
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          if (!err) {
            addChildLabel({
              labelId: this.labelId,
              childLabelIds: values.childLabelIds,
            }).then((res) => {
              if (res.code === 0) {
                this.$message.success("添加成功");
                resolve(true);
              }
            });
          }
        });
      });
    },
  },
};
</script>