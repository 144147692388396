<template>
  <a-spin :spinning="isSpinning">
    <div class="project-box">
      <div style="padding-left: 20px">
        <div class="project-title">
          <span class="name">{{ labelName }}</span>
          <span class="info"
            >项目数量：<span class="number">{{ totalProduct || 0 }}</span></span
          >
          <span class="info">
            状态：<span class="status">{{
              labelStatus ? "已生效" : "未生效"
            }}</span>
            <span v-if="hasAction('control-label-effect')">
              <a class="statusBtn" v-if="!labelStatus" @click="handleActive"
                >立即生效</a
              >
              <a class="statusBtn" v-else @click="handleDeactive">取消生效</a>
            </span></span
          >
          <a-button
            type="primary"
            class="edit-btn"
            @click="goto()"
            :disabled="labelStatus"
            title="请先取消生效"
            >编辑筛选条件</a-button
          >
        </div>
        <a-card class="label-list">
          <div class="title">
            关联的子标签 ({{ subLabelData.length }})
            <a-button
              type="primary"
              size="small"
              @click="handleAdd"
              v-if="hasAction('add-or-delete-label-relation')"
              ><a-icon type="plus" />新增子标签</a-button
            >
          </div>
          <div class="content">
            <span
              v-for="(label, index) in subLabelData"
              :key="index"
              class="label-item"
            >
              <a-popconfirm
                title="确认移除该子标签？"
                ok-text="是"
                cancel-text="否"
                @confirm="(e) => handleDelete(label)"
                v-if="hasAction('add-or-delete-label-relation')"
              >
                <a-icon slot="icon" type="close-circle" style="color: red" />
                <a-icon class="close-btn" type="close-square" /></a-popconfirm
              ><span>{{ label.name }}</span></span
            >
          </div>
        </a-card>
        <product-table
          class="project-table"
          v-show="labelStatus"
          ref="productTable"
          :labelId="labelId"
          :showData="labelStatus"
          @setTotalProduct="(num) => (totalProduct = num)"
        ></product-table>
      </div>
    </div>
  </a-spin>
</template>

<script>
import ProductTable from "../components/ProductTable";
import AddLabelForm from "../components/AddLabelForm";

import {
  getTagList,
  getChildLabels,
  deleteChildLabel,
  activeChildLabel,
  deactiveChildLabel,
} from "@/api/tag";

export default {
  components: {
    ProductTable,
  },
  data() {
    return {
      labelId: -1,
      labelData: null, // 所有标签的列表数据
      labelName: "",
      subLabelData: [],
      totalProduct: 0,
      isSpinning: false,
    };
  },
  computed: {
    labelStatus() {
      if (this.labelData === null) return null;
      let status;
      let labelId = parseInt(this.labelId);
      for (let item of this.labelData) {
        if (item.id === labelId) {
          status = item.effective;
        }
      }
      return status;
    },
  },
  created() {
    this.labelId = parseInt(this.$route.query.labelId);
    this.labelName = this.$route.query.labelName;
    this.loadData();
  },
  beforeRouteUpdate (to, from, next) {//解决不同参数同路径跳转不刷新问题
    this.labelId = parseInt(to.query.labelId)
    this.labelName = to.query.labelName
    this.loadData();
    next()
  },
  methods: {
    loadData() {
      this.getLabelData();
      this.loadSubLabels();
    },
    getLabelData() {
      getTagList().then((res) => {
        if (res.code === 0) {
          this.labelData = res.data;
        }
      });
    },
    loadSubLabels() {
      getChildLabels({ labelId: this.labelId }).then((res) => {
        if (res.code === 0) {
          this.subLabelData = res.data;
        }
      });
    },
    handleAdd() {
      let labelData = [];
      let subLabelIds = [];
      this.subLabelData.forEach((item) => {
        subLabelIds.push(item.id);
      });

      // 去除已是子条件的标签
      this.labelData.forEach((item) => {
        if (!subLabelIds.includes(item.id)) {
          labelData.push(item);
        }
      });
      // 去除当前labelId
      labelData = labelData.filter((item) => item.id !== this.labelId);
      this.$dialog(
        AddLabelForm,
        {
          labelId: Number(this.labelId),
          labelData,
          on: {
            ok: () => {
              this.loadData();
              this.$refs.productTable.fetchData(1);
            },
          },
        },
        {
          title: "添加子标签",
          width: 400,
          height: 300,
          centered: true,
          maskClosable: false,
        }
      );
    },
    handleDelete(record) {
      deleteChildLabel({ labelId: this.labelId, childLabelId: record.id }).then(
        (res) => {
          if (res.code === 0) {
            this.$message.success("删除成功");
            this.loadData();
            this.$refs.productTable.fetchData(1);
          }
        }
      );
    },
    handleActive() {
      this.$confirm({
        icon: () => {
          return (
            <a-icon
              type="close-circle"
              style="color: red"
              theme="filled"
            ></a-icon>
          );
        },
        content: (
          <div style="padding-left: 38px;">
            标签生效后，系统将会自动为所有符合该标签筛选条件的项目匹配此标签。
            <br />
            生效状态下不能对筛选条件进行编辑，匹配过程需耗费较多时间且无法中途停止，请确认您的筛选条件无误后再点击生效。
          </div>
        ),
        onOk: () => {
          this.activeExcute();
        },
        width: 440,
        okText: "生效",
        cancelText: "我再想想",
      });
    },
    activeExcute() {
      this.$refs.productTable.fetchData(0);
      this.isSpinning = true;
      activeChildLabel({ labelId: this.labelId })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success("已生效");
            this.labelData.forEach((item) => {
              if (item.id === this.labelId) {
                this.getLabelData();
                return;
              }
            });
          }
        })
        .finally(() => {
          this.isSpinning = false;
        });
    },
    handleDeactive() {
      this.$confirm({
        icon: () => {
          return (
            <a-icon
              type="close-circle"
              style="color: red"
              theme="filled"
            ></a-icon>
          );
        },
        content: (
          <div style="padding-left: 38px;">
            取消生效后，您之前在本页面匹配的项目标签将全部被删除，您可以再次编辑筛选条件并使标签重新生效。
            <br />
            请确认是否取消生效。
          </div>
        ),
        onOk: () => {
          this.deactiveExcute();
        },
        width: 440,
        okText: "取消生效",
        cancelText: "我再想想",
      });
    },
    deactiveExcute() {
      this.isSpinning = true;
      deactiveChildLabel({ labelId: this.labelId })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success("已取消生效");
            this.labelData.forEach((item) => {
              if (item.id === this.labelId) {
                this.getLabelData();
                this.$refs.productTable.fetchData(1, false);
                return;
              }
            });
          }
        })
        .finally(() => {
          this.isSpinning = false;
        });
    },
    goto() {
      this.$router.push({
        path: "/system/tag/condition",
        query: {
          labelId: this.labelId,
          labelName: this.labelName,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.active {
  color: #000;
  font-weight: bold;
}
.project-title {
  padding: 12px 0px;
  color: #999;
  .name {
    font-size: 26px;
    display: inline-block;
    margin-top: 2px;
    .active();
  }
  .info {
    margin-left: 85px;
    .number,
    .status {
      font-size: 18px;
      .active();
    }
    .statusBtn {
      margin-left: 25px;
    }
  }
  span {
    line-height: 36px;
  }
  .edit-btn {
    float: right;
  }
}
.label-list {
  color: #888;
  .title {
    .ant-btn {
      width: 120px;
      padding-left: 10px;
      margin-left: 10px;
      .anticon {
        font-size: 11px;
      }
      span {
        margin-left: 0;
      }
    }
  }
  .content {
    line-height: 36px;
    .label-item {
      margin-right: 50px;
      display: inline-block;
      span {
        padding-left: 5px;
      }
      .close-btn {
        cursor: pointer;
      }
    }
  }
}
.project-table {
  margin-top: 35px;
}
</style>